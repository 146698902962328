import { 
    FETCH_REFERRALBONUS, REFERRALBONUS_ADD_SUCCESS, FETCH_BONUSREFERRAL_ID, FETCH_REFERRALBONUS_ID_FAILED
    } from "../store/types";
    
    export const INITIAL_STATE = {
      bonus:null,
      loading: false,
      error:{
        flag:false,
        msg: null
      }
    }
    
    export const BonusReferralreducer =  (state = INITIAL_STATE, action) => {
      switch (action.type) {
        case FETCH_REFERRALBONUS:
          return {
            ...state,
            bonus:action.payload,
            loading:false
          };
        case REFERRALBONUS_ADD_SUCCESS:
          return {
            ...state,
            bonus:null,
            loading:false,
            error:{
              flag:true,
              msg:action.payload
            }
          };
          case FETCH_REFERRALBONUS_ID_FAILED:
            return{
                ...state,
                bonus:null,
                loading:false,
                error:{
                    flag:true,
                    msg:action.payload
                }
            }
          

          
          default:
          return state;
      }
    };