import {FETCH_REFERRALBONUS, REFERRALBONUS_ADD_SUCCESS, FETCH_BONUSREFERRAL_ID, FETCH_REFERRALBONUS_ID_FAILED} from "../store/types"
import {firebase} from "../config/configureFirebase";
import { onValue,push,set, ref} from "firebase/database";


export const list_referralBonus = (id)=>(dispatch)=>{
    const {
        referralBonusRef
    } = firebase;

    dispatch ({
        type:FETCH_BONUSREFERRAL_ID,
        payload:null
    })

    onValue (referralBonusRef(id),snapshot=>{   
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data).map(i => {
              data[i].id = i
              return data[i]
            });
            dispatch({
              type: FETCH_REFERRALBONUS,
              payload: arr.reverse()
            });
          } else {
            dispatch({
              type: FETCH_REFERRALBONUS_ID_FAILED,
              payload: "No SOS available."
            });
          }
    })
}

export const viewReferral =(refer)=>(dispatch)=>{
    const {
        singleReferralRef
    } = firebase;

    dispatch ({
        type:FETCH_BONUSREFERRAL_ID,
        payload:null
    })

    onValue (singleReferralRef(refer),snapshot=>{   
        if (snapshot.val()) {
            const data = snapshot.val();
            console.log(data);
            const arr = Object.keys(data).map(i => {
              data[i].id = i
              return data[i]
            });
            dispatch({
              type: FETCH_REFERRALBONUS,
              payload: arr.reverse()
            });
          } else {
            dispatch({
              type: FETCH_REFERRALBONUS_ID_FAILED,
              payload: "No SOS available."
            });
          }
    })
     
}

export const addBonusReferral = (id,data)=>(dispatch)=>{
    const {
        referralBonusRef,
    } = firebase;
       push(referralBonusRef(id), data).then((res) => {
             
                dispatch({
                    type: REFERRALBONUS_ADD_SUCCESS,
                    payload: 'Esta registrado',
                    });    
                }).catch(error => {
                    dispatch({
                        type: FETCH_REFERRALBONUS_ID_FAILED,
                        payload: error.code + ": " + error.message,
                    });
                });


}