import {
    FETCH_PAYMENT_METHODS,
    FETCH_PAYMENT_METHODS_SUCCESS,
    FETCH_PAYMENT_METHODS_FAILED,
    UPDATE_WALLET_BALANCE,
    UPDATE_WALLET_BALANCE_SUCCESS,
    UPDATE_WALLET_BALANCE_FAILED,
    CLEAR_PAYMENT_MESSAGES,
    UPDATE_PAYMENT_METHOD
} from "../store/types";
import { RequestPushMsg } from '../other/NotificationFunctions';
import { firebase } from '../config/configureFirebase';
import store from '../store/store';
import { onValue, get, update, push } from "firebase/database";

export const fetchPaymentMethods = () => (dispatch) => {

    const {
      config,
      paymentSettingsRef
    } = firebase;
  
    dispatch({
      type: FETCH_PAYMENT_METHODS,
      payload: null
    });

    const usertype = store.getState().auth.profile.usertype;

    if(usertype == 'admin'){
        onValue(paymentSettingsRef, snapshot => {
            const data = snapshot.val(); 
            if(data){
              dispatch({
                type: FETCH_PAYMENT_METHODS_SUCCESS,
                payload: data
              });
            } else {
              dispatch({
                type: FETCH_PAYMENT_METHODS_FAILED,
                payload: store.getState().languagedata.defaultLanguage.no_provider_found,
              });
            }
          });
    } else { 

        const settings = store.getState().settingsdata.settings;
        let host = window && window.location && settings.CompanyWebsite === window.location.origin? window.location.origin : `https://${config.projectId}.web.app`
        let url = `${host}/get_providers`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {

                console.log(responseJson)
                if (responseJson.length > 0) {
                    dispatch({
                        type: FETCH_PAYMENT_METHODS_SUCCESS,
                        payload: responseJson,
                    });
                }else{
                    dispatch({
                        type: FETCH_PAYMENT_METHODS_FAILED,
                        payload: store.getState().languagedata.defaultLanguage.no_provider_found,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_PAYMENT_METHODS_FAILED,
                    payload: store.getState().languagedata.defaultLanguage.provider_fetch_error + ": " + error.toString(),
                });
            });
    }
  };

  export const editPaymentMethods = (data) => (dispatch) => {
    const {
        paymentSettingsRef
    } = firebase;
    dispatch({
      type: UPDATE_PAYMENT_METHOD,
      payload: data 
    });
    update(paymentSettingsRef, data);
  }
  


export const clearMessage = () => (dispatch) => {
    dispatch({
        type: CLEAR_PAYMENT_MESSAGES,
        payload: null,
    });    
};


export const addToWallet = (uid, amount) => async (dispatch) => {
    const {
        walletHistoryRef,
        singleUserRef,
        settingsRef
    } = firebase;

    dispatch({
        type: UPDATE_WALLET_BALANCE,
        payload: null
    });

    const settingsdata = await get(settingsRef);
    const settings = settingsdata.val();

    onValue(singleUserRef(uid), snapshot => {
        if (snapshot.val()) {
            let walletBalance = parseFloat(snapshot.val().walletBalance);
            const pushToken = snapshot.val().pushToken;
            walletBalance = parseFloat((parseFloat(walletBalance) + parseFloat(amount)).toFixed(settings.decimal));
            let details = {
                type: 'Credit',
                amount: parseFloat(amount),
                date: new Date().getTime(),
                txRef: 'AdminCredit'
            }
            update(singleUserRef(uid),{walletBalance: walletBalance}).then(() => {
                push(walletHistoryRef(uid), details).then(()=>{
                    dispatch({
                        type: UPDATE_WALLET_BALANCE_SUCCESS,
                        payload: null
                    });
                }).catch(error=>{
                    dispatch({
                        type: UPDATE_WALLET_BALANCE_FAILED,
                        payload: error.code + ": " + error.message,
                    });            
                })
                if(pushToken){
                    RequestPushMsg(
                        pushToken,
                        {
                            title: store.getState().languagedata.defaultLanguage.notification_title,
                            msg:  store.getState().languagedata.defaultLanguage.wallet_updated,
                            screen: 'Wallet'
                        }
                    );
                }
            }).catch(error=>{
                dispatch({
                    type: UPDATE_WALLET_BALANCE_FAILED,
                    payload: error.code + ": " + error.message,
                });
            });
            
        }
    }, {onlyOnce: true});
};


export const withdrawBalance = (profile, amount) => async (dispatch) => {

    const {
        withdrawRef,
    } = firebase;
    
    dispatch({
        type: UPDATE_WALLET_BALANCE,
        payload: null
    });

     push(withdrawRef, {
        uid : profile.uid,
        name : profile.firstName +  ' ' + profile.lastName,
        amount : parseFloat(amount),
        date : new Date().getTime(),
        bankName : profile.bankName? profile.bankName : '',
        bankCode : profile.bankCode? profile.bankCode : '',
        bankAccount : profile.bankAccount? profile.bankAccount : '',
        processed:false
    });

};


export const withdrawReferral = (profile, amount) => async (dispatch) => {

    const {
        withdrawComRef,
        referralBonusRef,
        settingsRef,
        singleUserRef,
    } = firebase;
    
    dispatch({
        type: UPDATE_WALLET_BALANCE,
        payload: null
    });

    const settingsdata = await get(settingsRef);
    const conf = settingsdata.val();
    let winner = amount * 95 / 100;
    let walletReferral = profile.walletReferral - parseFloat(amount);
   
    update(singleUserRef(profile.uid),{walletReferral: walletReferral}).then(async ()=>{

               await push(withdrawComRef, {
                    uid : profile.uid,
                    name : profile.firstName +  ' ' + profile.lastName,
                    amount : parseFloat(winner),
                    date : new Date().getTime(),
                    bankName : profile.bankName? profile.bankName : '',
                    bankCode : profile.bankCode? profile.bankCode : '',
                    bankAccount : profile.bankAccount? profile.bankAccount : '',
                    processed:false
                });
                const data ={
                        amount: amount,
                        concepto: "Withdrawable "+winner+" "+conf.code,
                        time: new Date().getTime(),
                        type: 'Debited'
                }
               await  push(referralBonusRef(profile.uid), data);

               await pay_referral(profile, amount, conf);
             
     })


       
};

const pay_referral = async (user, amount, set) => {

    const {
        singleUserRef,
        referralBonusRef
    } = firebase;


      let win = amount * 2 / 100;
      let usuario  = await get(singleUserRef(user.signupViaReferral));
      let ref_id = user.signupViaReferral;
      if(!ref_id) return;
      let usua =  usuario.val();
      let deta; let walletReferral;
      const list = [{v:1},{v:2},{v:3}]
      let concepto = "Bonus referral of "+user.firstName+" "+user.lastName+" "+amount+" "+set.code+" level ";
     
        for(let i = 1; i<4;i++){
               let  win_lv = await pay_level(i, win);
         
             if(!usua) return; 

         //    console.log("paga "+ ref_id+" "+win_lv+" level "+i);

                if(!usua.walletReferral)    walletReferral = 0;
                else                        walletReferral = parseFloat(usua.walletReferral);
                walletReferral                             = walletReferral + parseFloat(win_lv);
                deta                                       ={
                                                                    amount: win_lv,
                                                                    concepto: concepto+""+i,
                                                                    time: new Date().getTime(),
                                                                    type: 'Credited'
                                                            }
                                                    
                await  update(singleUserRef(ref_id),{walletReferral: walletReferral})
                await   push(referralBonusRef(ref_id), deta);
                 

                if(!usua.signupViaReferral){
                       usua = null;
                       ref_id = null;
                }else{
                      let n_user = await get(singleUserRef(usua.signupViaReferral))
                       ref_id = usua.signupViaReferral;
                        usua = n_user.val();
                       
                }
            }
   

}

const pay_level = async(lv, monto)=>{
    let res = 0;
    switch(lv){
        case 1: res = monto  * 50 / 100; break;
        case 2:  res = monto * 40 / 100; break;
        case 3:  res = monto * 10 / 100; break;
    }

    return parseFloat(res);

}
