export const FirebaseConfig = {
	"projectId": "conecta2-300c3",
	"appId": "1:399794325298:web:11d4ce1ea99132a2b7792b",
	"databaseURL": "https://conecta2-300c3-default-rtdb.firebaseio.com",
	"storageBucket": "conecta2-300c3.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDqcsY6iD3x20a1ug67-v6DycHCFx_2tt0",
	"authDomain": "conecta2-300c3.firebaseapp.com",
	"messagingSenderId": "399794325298",
	"measurementId": "G-H78WJ058VX"
};