import {
    FETCH_WITHDRAWSREFER,
    FETCH_WITHDRAWSREFER_SUCCESS,
    FETCH_WITHDRAWSREFER_FAILED,
    EDIT_WITHDRAWSREFER
  } from "../store/types";
  import { firebase } from '../config/configureFirebase';
  import { onValue, child, update } from "firebase/database";
  
  export const fetchWithdrawsRefer = ()=>(dispatch) => {
    console.log("dentro de la busquedad");
  
    const {
        withdrawComRef
    } = firebase;
    

    dispatch({
      type: FETCH_WITHDRAWSREFER,
      payload: null
    });
   onValue(withdrawComRef, snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i
          return data[i]
        });
        console.log(data);
        dispatch({
          type: FETCH_WITHDRAWSREFER_SUCCESS,
          payload: arr.reverse()
        });
      } else {
        dispatch({
          type: FETCH_WITHDRAWSREFER_FAILED,
          payload: "No WITHDRAWS available."
        });
      }
    });
  };
  
  export const completeWithdrawRefer = (entry) => (dispatch) => {
  
    const {
        withdrawComRef
    } = firebase;
    
    dispatch({
      type: EDIT_WITHDRAWSREFER,
      payload: entry
    });
    update(child(withdrawComRef, entry.id),{ ...entry, processed: true, processDate: new Date().getTime() });
  }